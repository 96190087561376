.searchInputs {
    display: flex;
}
  
input {
  padding-left: 20px;
}
  
.search input {
    background-color: white;
    border: 0;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    font-size: 18px;
  
    height: 45px;
    width: 350px;
}
  
.search {
    display: flex;
    flex-direction: column;
    overflow: visible;
    align-items: center;
    width:unset;
    height: 45px;
    padding-right: 1rem;
}
  
.searchIcon {
    height: 47px;
    width: 50px;
    background-color: white;
    
    place-items: center;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

.searchInputs input:focus {
    outline: none; /* Remove the default focus outline */
    box-shadow: none; /* Remove the box shadow when the input is in focus */
}

.dataResult {
    margin-top: 52px;
    width: 425px;
    height: 200px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    position: absolute;
    overflow: hidden;
    overflow-y: auto;
    border-radius: 5px;
  }
  
  .dataResult::-webkit-scrollbar {
    display: none;
  }
  
  .dataResult .dataItem {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    color: black;
  }
  
  .dataItem p {
    margin-left: 10px;
  }
  a {
    text-decoration: none;
  }
  
  a:hover {
    background-color: lightgrey;
  }
  
  @media only screen and (max-width: 475px){
    .dataResult{
      width: 300px;
    }

    .search input{
      width: 300px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    .search{
      padding-left: 1rem;
      
    }

    .searchIcon{
      width: 0%;
    }
  }
  
