.navbar {
  background-color: #000;
  color: #fff;
  padding: 30px 0px; /* Adjusted padding */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  transition: top 0.6s;
}

.container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.logo {
  font-size: 30px;
  font-weight: bold;
}

.nav-links {
  list-style: none;
  margin: 0;
  padding: 10px;
  cursor: pointer;
}

.nav-links li {
  display: inline-block;
  margin-right: 100px;
  font-size: 18px;
  
}

.nav-links li:last-child {
  margin-right: 0;
}

.menu-icon {
  display: none; 
  cursor: pointer;
}

.menu-icon-line {
  width: 25px;
  height: 3px;
  background-color: #fff;
  margin: 5px;
  transition: transform 0.3s ease; /* Added transition */
}

.open {
  transform: rotate(-45deg);
}


.nav-links li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.2s ease;
 
}

.nav-links li a:hover {
  color: #3B3B3B;
}

.navbar.show {
  top: 0;
}

.logo sup {
  font-size: .5em;
}


.navbar.hide {
  top: -110px; 
}

.nav-links a:hover{
  color: gray;
  background-color: black;
}

@media only screen and (max-width: 800px) {
  
  .logo{
    align-self: left;
    padding-right: 10rem;
    padding-left: .5rem;
  }

  .navbar.hide {
    top: -300px; 
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: black;

    text-align: center; 
    transition: opacity 0.3s ease; 
    opacity: 0; 
    padding-bottom: 1.5rem;
  }

  .nav-links li{
   padding-top: 25px; 
   padding-bottom: 15px;
   width: 100%;
  }


  .nav-links.mobile-menu-open {
    display: flex;
    opacity: 1;
  }

  .menu-icon {
    
    display: block; 
  }

  .container {
    position: relative;
  }
}
