

.about {
    background-color: black; /* Green background */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content-about {
    text-align: center;
    color: #fff; /* White text */
    margin-left: 10%;
    margin-right: 10%;
  }



  .about h4{
    text-align: left;
    font-size: 3.5rem;
    margin-bottom: 0rem;
  }

  .text-image{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .about-text{
    text-align: left;
    font-size: 1.13rem;
    grid-column: 1;
  }

  .lebron{
    width: 300px;
    border-radius: 25px;
    justify-self: center;
    align-self: top;
    grid-column: 2;
  }

  /* .content-about {
    display: flex; 
    align-items: flex-start;
  } */

.header-text-container {
    margin-right: 20px; /* Optional spacing between text and image */
}

@media only screen and (max-width: 900px){


  .about{
    height: 150vh;
  }
  .content-about{
    padding-left: 1rem;
  }

  .text-image{
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }

  .about-text{
    grid-column: 1;
    grid-row: 1;
    font-size: 1rem;
  }

  .lebron{
    grid-column: 1;
    grid-row: 2;
  }

  @media only screen and (max-height: 700px){
    .about{
      height: 130vh;
    }

    .about-text{
      grid-column: 1;
      grid-row: 1;
      font-size: 0.8rem;
    }
  }

}



  
  
  

  
  