/* Home.css */

.home {
    display: flex;
    align-items: center;
    height: 100vh;
    background-color: #000; /* Black background */
  }
  
  .video-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.3;
  }
  
  
  .content-home {
    flex: 1;   /* Take up 50% of the available space */
    justify-content: center;
    align-items: center;
    color: #fff; /* White text */
    z-index: 1; 
    margin-bottom: 0rem;
    margin-left: 23%;
    margin-right: 23%
  }

  .content-home h1{
    margin-top: 0rem;
    font-size: 6rem;
    margin-bottom: 0rem;
    text-align: left;
  }

  .content-home h2{
    margin-bottom: 0rem;
    font-size: 1.3rem;
    text-align: left;
  }
  
  .content-home h3{
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 1rem;
    text-align: left;
  }

  #by-who{
    margin-bottom: 2rem;
  }

  .button-container-home{
    text-align: left;
    margin-top: 1rem;
  }

  .button-28 {
    appearance: none;
    
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 12px 14px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 150px;
    will-change: transform;
  }
  
  .button-28:disabled {
    pointer-events: none;
  }
  
  .button-28:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
  
  .button-28:active {
    box-shadow: none;
    transform: translateY(0);
  }

  #main-title sup {
      font-size: .55em;
  }


  
  