/* Predict.css */

.predict {
    background-color: black; /* Green background */
    height: 100vh;
}

.search-bar-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5rem;
  align-items: center;

}


.predict-button {
  appearance: none;
  border: 2px solid #1A1A1A;
  border-radius: 15px;
  box-sizing: border-box;
  color: #3B3B3B;
  cursor: pointer;
  display: inline-block;
  font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  font-size: 18px;
  font-weight: bold;
  line-height: normal;
  margin: 0;
  min-height: 52px;
  min-width: 0;
  outline: none;
  padding: 12px 14px;
  text-align: center;
  text-decoration: none;
  transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  width: 150px;
  will-change: transform;
  height: 52px;
}

.predict-button:disabled {
  pointer-events: none;
}

.predict-button:hover {
  color: #fff;
  background-color: #1A1A1A;
  box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
  transform: translateY(-2px);
}

.predict-button:active {
  box-shadow: none;
  transform: translateY(0);
}
  
  .content3 {
    color: #fff; /* White text */
    padding-top: 5rem;
    align-items: center;
    justify-content: center;
  }

  h5{
    font-size: 3.5rem;
    text-align: center;
    margin-top: 0rem;
  }

  
  .custom-select{
    padding: 1rem;
  }

  .team-dropdown{
    border-radius: 15px;
    height: 50px;
    width: 150px;
    text-align: center;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 15px;
    line-height: normal;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    will-change: transform;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    text-decoration: none;
    border: 2px solid #1A1A1A;
  }

  .team-dropdown:disabled {
    pointer-events: none;
  }
  
  .team-dropdown:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
  
  .team-dropdown:active {
    box-shadow: none;
    transform: translateY(0);
  }

  .error-text{
    color: red;
  }

  .spinner-container{
    width: 100%;
    text-align: center;
    justify-content: center;
    
    display: flex;
    align-items: center;
    text-align: center;
    
    margin: auto;
    justify-content: center;
  }

  .spinner{
    padding-left: 50%;
  }
  h5{
    margin-bottom: 0rem;
  }

  h7{
    margin-top: 0rem;
   
  }

  @media only screen and (max-width: 800px){
    .search-bar-container{
      flex-direction: column;
    }
    .button-container{
      padding-left: 12rem;
      margin-top: -110px;
      margin-bottom: 80px;
    }
    .custom-select{
      padding-right: 15rem;
    }
  }

  @media only screen and (max-width: 500px){
    .search-bar-container{
      flex-direction: column;
    }
    .button-container{
      padding-left: 11rem;
      margin-top: -110px;
      margin-bottom: 80px;
    }
    .custom-select{
      padding-right: 12rem;
    }

    @media only screen and (max-height: 700px){
      .predict{
        height: 110vh;
      }

      h7{
        font-size: 1rem;
        padding: 5%;
      }
    }
  }

