/* Predict.css */

.contact {
    background-color: black;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .content2 {
    flex: 1;   /* Take up 50% of the available space */
    justify-content: center;
    align-items: center;
    color: #fff; /* White text */
    z-index: 1; 
    margin-bottom: 0rem;
    margin-right: 23%;
    margin-left: 23%;
    
  }

  .video-bg2 {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    opacity: 0.1;
  }
  

  .content2 h1{
    margin-top: 0rem;
    font-size: 4.5rem;
    margin-bottom: 0rem;
    text-align: right;
  }

  .content2 h2{
    margin-bottom: 0rem;
    font-size: 1.3rem;
    text-align: right;
  }
  
  .content2 h3{
    margin-bottom: 0rem;
    margin-top: 0rem;
    font-size: 1rem;
    text-align: right;
  }

  .button2 {
    appearance: none;
    border: 2px solid #1A1A1A;
    border-radius: 15px;
    box-sizing: border-box;
    color: #3B3B3B;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert,-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    margin: 0;
    min-height: 60px;
    min-width: 0;
    outline: none;
    padding: 12px 18px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 160px;
    will-change: transform;
  }
  
  .button2:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
  }
  
  .button2:active {
    box-shadow: none;
    transform: translateY(0);
  }

  .button-container2{
    margin-top: 1.5rem;
    text-align: right;
  }

  .contact-photo{
    width: 35%;
    height: 50%;
    margin-right: 18rem;
  }

  #emailMe {
    text-decoration: none;
    color: black;
  }